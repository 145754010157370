<template>
  <v-footer class="pa-3">
    Developed by Ganesh Ranabhat
    <v-spacer></v-spacer>
    <div>&copy; {{ new Date().getFullYear() }}</div>
  </v-footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>