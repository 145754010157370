import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import StoryblokVue from 'storyblok-vue'
import VueAnalytics from 'vue-analytics'
import VueKinesis from 'vue-kinesis'



Vue.config.productionTip = false
const isProd = process.env.NODE_ENV === "production"

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')


Vue.use(VueKinesis)
Vue.use(StoryblokVue)
Vue.use(VueAnalytics, {
    id: 'UA-139190314-1',
    router,
    debug: {
        enabled: !isProd,
        sendHitTask: isProd
    }
})